.main{
    color: #333333;
    width: 100%;
    padding-top: 10vh;
    transition: ease 4s;
}

.night{
    background-color: #333333;
    color: rgb(200, 200, 200);
    padding-top: 10vh;
    transition: ease 4s;
}

.up{
    background-color: var(--primary-purple);
    color: white;
    display: none;
    padding: 1rem 0.5rem;
    position: fixed;
    /* left: 90%; */
    margin-top: 60vh;
    width: max-content;
    transition: ease 1s;
    z-index: 1;
}
.down{
    background-color: var(--primary-purple);
    color: white;
    display: none;
    padding: 1rem 0.5rem;
    position: fixed;
    margin-top: 70vh;
    width: max-content;
    transition: ease 1s;
    z-index: 1;
}

.downNight{
    background-color: var(--primary-purple);
    color: rgb(200, 200, 200);
    display: none;
    padding: 1rem 0.5rem;
    position: fixed;
    /* left: 90%; */
    margin-top: 70vh;
    width: max-content;
    transition: ease 1s;
    z-index: 1;
}

.upNight{
    background-color: var(--primary-purple);
    color: rgb(200, 200, 200);
    display: none;
    padding: 1rem 0.5rem;
    position: fixed;
    margin-top: 60vh;
    width: max-content;
    transition: ease 1s;
    z-index: 1;
}

.up,.down,.downNight,.upNight{
    cursor:var(--cursor-pointer), pointer;
}

@media only screen and (max-width: 768px) {
    .main, .night{
        padding-top: 0;
    }
}