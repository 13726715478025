.a{
    display: block;
    border-radius: 1.5rem;
    cursor: var(--cursor-pointer), pointer;
    color: white;
    margin: 0 1rem;
    padding: 0.5rem 1rem;
}

.a:hover{
    color: var(--primary-purple);
    background-color: aliceblue;
    border-radius: 1.5rem;
    transition: 0.5s;
}

.day{
    background-color: #0705656c;
    color: red;
}

.dayNigth{
    color: white !important;
    cursor: url('https://cur.cursors-4u.net/anime/ani-12/oth1118.ani'), url('https://cur.cursors-4u.net/anime/ani-12/oth1118.png'), pointer;
    width: 2rem;
}

.Header{
    
    background:
    /* top, transparent red, faked with gradient */ 
    linear-gradient(
      rgba(7, 5, 101, 1), 
      rgba(7, 5, 101, 0.7)
    ),
    /* bottom, image */
    url('../../assets/header-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: fixed;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20%;
    /*  */
    transition: all ease-in-out 4s;
    width: 100%;
    z-index: 99;
}

.Header:hover{
    background:
    /* top, transparent red, faked with gradient */ 
    linear-gradient(
      rgba(7, 5, 101, 0.9), 
      rgba(58, 5, 101, 0.7)
    ),
    /* bottom, image */
    url('../../assets/header-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.links{
    display: flex;
    align-items: center;
    transition: all ease 4s;
}

.logo{
    color: white;
    cursor: url('https://cur.cursors-4u.net/anime/ani-11/ani1037.ani'), url('https://cur.cursors-4u.net/anime/ani-11/ani1037.png'), pointer;
    font-family:'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
    font-size: 25px;
    font-weight: bold;
}

.logo:hover{
    color: var(--primary-purple);
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #ffffff;
    transition: 2s;
}

.largMenu{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 2rem;
    width: 100%;
}

.menu{
    display: none;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    position: relative;
    padding: 0rem 2rem;
    width: 100%;
}

.menuLinks{
    background:
    /* top, transparent red, faked with gradient */ 
    linear-gradient(
      rgba(7, 5, 101, 0.9), 
      rgba(58, 5, 101, 0.7)
    ),
    /* bottom, image */
    url('../../assets/header-bg.png');
    box-shadow: 1px 2px 4px var(--primary-purple);
    display: none;
    font-size: x-small;
    position: absolute;
    width: 100%;
    height: 80vh;
    top: 100%;
    transition: all ease-in-out 0.5s;
    z-index: 1;
}

.menuIcon{
    color: aliceblue;
    cursor: var(--cursor-pointer), pointer;
    font-size: larger;
    padding: 0.5rem;
}

.menuIcon:hover{
    background-color: rgba(240, 248, 255, 0.544);
    color: var(--primary-purple);
}

.night{
    background-color: black;
    color: whitesmoke;
}

.resume{
    border: 0.1rem solid aliceblue;
    border-radius: 1.5rem;
}

@media only screen and (max-width: 768px) {
    .links{
        display: flex;
        height: 80vh;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .menu{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .logoAndTheme{
        display: flex;
        align-items: center;
        gap: 2rem;
        justify-content: space-between;
    }
    .Header{
        flex-direction: column;
    }
    .largMenu{
        display: none;
    }
}