.exp{
    padding: 2rem 5rem;
    text-align: left;
}

.titleDay{
    color: var(--primary-purple);
    text-align: left;
}

.titleNight{
    color: rgb(200, 200, 200);
    text-align: left;
}

.experienceContainer {
    padding: 4rem 1rem;
}

.content {
    max-width: 1200px;
    margin: 0 auto;
}

.title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 3rem;
}

.jobList {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

/* Target even-numbered jobs */
.jobList > div:nth-child(even) {
    align-self: flex-end;
}

/* Target odd-numbered jobs */
.jobList > div:nth-child(odd) {
    align-self: flex-start;
}