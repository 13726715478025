.a{
    background-color: var(--primary-purple);
    border-radius: 1.5rem;
    color: white;
    display: inline-block;
    margin: 0 1rem;
    padding: 0.5rem 1rem;
}

.aNight{
    border-radius: 1.5rem;
    color: white;
    padding: 0.5rem 1rem;
}

.a:hover, .aNight:hover{
    color: var(--primary-purple);
    background-color: aliceblue;
    filter: drop-shadow( 3px 4px 5px var(--primary-purple));
    border-radius: 1.5rem;
    transition: 0.5s;
}

.resume{
    border: 0.1rem solid aliceblue;
    border-radius: 1.5rem;
}