.Bio{
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    text-align: left;
}

.social{
    width: 2rem;
    padding: 0.3rem 0.5rem;
    border-radius: 0.5rem 0;
    transition: ease-out 0.5s;
}

.social>a{
    cursor: url('https://cur.cursors-4u.net/anime/ani-11/ani1037.ani'), url('https://cur.cursors-4u.net/anime/ani-11/ani1037.png'), pointer;
}

.social:hover{
    background-color: thistle;
    width: 4rem;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    text-align: left;

}

.job{
    font-size: 1.5rem;
    color: var(--primary-purple);
    margin: 0rem 0rem 2rem 0rem;
}

.jobNight{
    background-color: rgb(200, 200, 200);
    color: var(--primary-purple);
    font-size: 1.5rem;
    margin: 0rem 0rem 2rem 0rem;
}

.myName{
    font-size: 1.5rem;
    margin: 0rem 0rem;
}

.name,.nameNight{
    font-weight:900;
    font-size: 3rem;
    margin: 0rem 0rem;
    transition: ease 0.5;
}

.nameNight:hover{
    color: aliceblue;
}
.name:hover{
    background-color: rgb(200, 200, 200);
}

.profileImg{
    background-image: url(../../assets/profile-img-small.png);
    background-size: cover;
    background-position: center;
    border-radius: 0 6rem;
    filter: drop-shadow( 16px 16px 0px var(--primary-purple));
    overflow:hidden;
    width: 30rem;
    transition: ease-in 0.5s;
}

.profileImg:hover{
    filter: drop-shadow( 6px 6px 4px var(--primary-purple));
    width: 30rem;
}

.profileImgContainer{
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin-right: 2rem;
    height: 30rem;
    width: 30rem;
}

.socialLinks{
    display: flex;
    flex-direction: column;
    margin-top: 7rem;
    
}

.summary{
    font-size: 0.8rem;
    width: 100%;
    padding-right: 30%;
}

@media only screen and (max-width: 768px) {
    .Bio{
        flex-direction:column-reverse;
    }
    .summary{
        padding-right: 0;
    }
    .profileImg, .profileImgContainer{
        width: 20rem;
    }
}