.educations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Target even-numbered jobs */
.educations > div:nth-child(even) {
    align-self: flex-end;
}

/* Target odd-numbered jobs */
.educations > div:nth-child(odd) {
    align-self: flex-start;
}

