
:root{
  --primary-purple: #070565CC;
  --night-purple: #0704b0cc;
  --orange-secondary: #FA8F21;
  --cursor-pointer: url('https://cur.cursors-4u.net/anime/ani-12/oth1118.ani'), url('https://cur.cursors-4u.net/anime/ani-12/oth1118.png');
}

*{
  box-sizing: border-box;
}

a{
  cursor: var(--cursor-pointer) , pointer;
  text-decoration: none;
}

body {
  cursor: url('https://cur.cursors-4u.net/anime/ani-11/ani1037.ani'), url('https://cur.cursors-4u.net/anime/ani-11/ani1037.png'), auto ;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button{
  cursor: var(--cursor-pointer) , pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.h2Day{
  color: var(--primary-purple);
    text-align: left;
}

.h2Night{
  color: rgb(200, 200, 200);
  text-align: left;
}

html {
  scroll-behavior: smooth;
}

img{
  width: 100%;
}

input:focus, textarea:focus{
  outline: none;
}

.sectionContainer{
  padding: 2rem;
  text-align: left;
}

.sectionContainerDay{
  background-color: #EBEBEBCC;
  padding: 2rem;
  text-align: left;
}